import "core-js/modules/es.array.push.js";
import "core-js/modules/es.function.name.js";
import { AccountStorage } from "@services/account";
var accountStorage = new AccountStorage();
export default {
  data: function data() {
    return {
      isExternal: false,
      ind: null,
      navList: [{
        name: "我的账户",
        img: require("./images/account.png"),
        row: [{
          name: "我的账户",
          path: "/user-center/my-account"
        }
        // {
        //   name: "欠款信息",
        //   path: "/user-center/debt-message"
        // },
        // {
        //   name: "提现记录",
        //   path: "/user-center/postal-cash-history"
        // }
        ]
      }, {
        id: 1,
        name: "订单",
        img: require("./images/order.png"),
        row: [{
          name: "全部订单",
          path: "/user-center/order/0"
        }
        // {
        //   name: "历史采购"
        // },
        // {
        //   name: "我的账单"
        // },
        // {
        //   name: "退货/售后",
        //   path: "/user-center/after-sale"
        // }
        ]
      },
      // {
      //   name: "我的账单",
      //   img: require("./images/order.png"),
      //   row: [
      //     {
      //       name: "全部账单",
      //       path: "/user-center/bill/list/0"
      //     }
      //   ]
      // },
      {
        id: 3,
        name: "优惠券",
        img: require("./images/coupon.png"),
        row: [{
          name: "全部优惠券",
          path: "/user-center/coupon"
        }]
      }, {
        id: 13,
        name: "报计划",
        img: require("./images/collect.png"),
        row: [{
          name: "我的计划本",
          path: "/user-center/planBook"
        }]
      }, {
        id: 2,
        name: "收藏",
        img: require("./images/collect.png"),
        row: [{
          name: "我的收藏",
          path: "/user-center/enshrine"
        }]
      },
      // {
      //   id: 4,
      //   name: "资质管理",
      //   row: [
      //     {
      //       name: "我的资质"
      //     },
      //     {
      //       name: "以购药品报告"
      //     },
      //     {
      //       name: "普健企业资质"
      //     }
      //   ]
      // },
      // 暂时隐藏我的积分12.17
      // {
      //   id: 5,
      //   name: "积分",
      //   img: require("./images/integral.png"),
      //   row: [
      //     {
      //       name: "我的积分",
      //       path: "/user-center/integral"
      //     }
      //   ]
      // },
      {
        id: 6,
        name: "信息管理",
        img: require("./images/message.png"),
        row: [{
          name: "基本信息管理",
          path: "/user-center/user-message"
        }, {
          name: "收货地址管理",
          path: "/user-center/address"
        }
        // {
        //   name: "修改密码"
        // }
        ]
      }, {
        id: 7,
        name: "发票管理",
        img: require("./images/invoice.png"),
        row: [
        // {
        //   name: "我的发票"
        // },
        {
          name: "发票中心",
          path: "/user-center/electronicInvoice"
        }, {
          name: "发票抬头管理",
          path: "/user-center/invoice"
        }]
      }, {
        id: 8,
        name: "资料下载",
        img: require("./images/download.png"),
        row: [{
          name: "药品资料下载",
          path: "/user-center/data-download"
        }, {
          name: "药品检验报告下载",
          path: "/user-center/drug-download"
        }]
      },
      // {
      //   id: 9,
      //   name: "登记",
      //   row: [
      //     {
      //       name: "缺货/新品登记"
      //     }
      //   ]
      // },
      {
        id: 10,
        name: "售后",
        img: require("./images/sale.png"),
        row: [{
          name: "投诉建议",
          path: "/user-center/complain"
        }, {
          name: "我的客服",
          path: "/user-center/service"
        }]
      }, {
        id: 11,
        name: "审核",
        img: require("./images/sale.png"),
        row: [{
          name: "我的审核",
          path: "/user-center/my-audit"
        }]
      }, {
        id: 11,
        name: "拼单拼团",
        img: require("./images/sale.png"),
        row: [{
          name: "我的拼团",
          path: "/user-center/group-booking"
        }]
      }, {
        id: 11,
        name: "资质证照",
        img: require("./images/order.png"),
        row: [{
          name: "我的资质",
          path: "/user-center/my-licence"
        }]
      }]
    };
  },
  created: function created() {
    var userInfo = accountStorage.getCacheUserInfo();
    if (userInfo) {
      this.isExternal = userInfo.type == 1 ? true : false;
    }
  },
  computed: {
    isOrderDetail: function isOrderDetail() {
      return this.$route.name === "integral";
    },
    isDetail: function isDetail() {
      return this.$route.name === "order";
    },
    isafterSaleDetail: function isafterSaleDetail() {
      return this.$route.name === "after-sale";
    },
    isActivity: function isActivity() {
      return this.$route.path === "/user-center/my-account";
    },
    isEnshrine: function isEnshrine() {
      return this.$route.name === "enshrine";
    },
    isDebtMessageDetail: function isDebtMessageDetail() {
      return this.$route.name === "debtMessageDetail";
    },
    isDebtMessage: function isDebtMessage() {
      return this.$route.name === "debtMessage";
    }
  },
  methods: {
    toLink: function toLink(car, i) {
      //限制潜客信息
      if (car.name != "我的账户" && car.name != "基本信息管理" && car.name != "我的客服" && car.name != "我的审核" &&
      // car.name=='欠款信息'||
      // car.name=='投诉建议' ||
      // car.name=='全部订单' ||
      // car.name=='我的拼团' ||
      // car.name=='退货/售后' ||
      // car.name=='提现记录' ||
      // car.name=='药品资料下载' ||
      // car.name=='我的积分'
      this.isExternal) {
        this.$buefy.toast.open({
          message: "您尚未成为正式会员，无法进入该页面",
          type: "is-danger"
        });
        return;
      }
      this.ind = i;
      var path = car.path;
      if (path === undefined) {
        return false;
      }
      this.$router.push({
        path: "".concat(path)
      });
    }
  }
};