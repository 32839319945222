var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "address-box"
  }, [_c('PageTop', {
    attrs: {
      "isLogin": _vm.isLogin
    }
  }), _c('HeadeSearch', {
    ref: "handleGetSum"
  }), _c('section', {
    staticClass: "center-content"
  }, [!_vm.isOrderDetail && !_vm.isOrderDetailRebate && !_vm.isApplyGoods && !_vm.isAfterSaleDetail && !_vm.isSalesDetail ? _c('div', {
    staticClass: "content-left"
  }, [_c('NavList')], 1) : _vm._e(), _c('div', {
    staticClass: "address-right"
  }, [_c('keep-alive', [_vm.$route.meta.keepAlive ? _c('router-view', {
    on: {
      "handleGetSum": _vm.handleGetSum
    }
  }) : _vm._e()], 1), !_vm.$route.meta.keepAlive ? _c('router-view', {
    on: {
      "handleGetSum": _vm.handleGetSum
    }
  }) : _vm._e()], 1)]), _c('PageFooter')], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };