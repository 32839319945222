import "core-js/modules/es.function.name.js";
import { HeadeSearch } from "@components/head-search";
import { PageFooter } from "@components/page-footer";
import { NavList } from "@components/nav-list";
import { Account } from "@services/account";
import { PageTop } from "@components/page-top";
var account = new Account();
export default {
  components: {
    HeadeSearch: HeadeSearch,
    PageFooter: PageFooter,
    NavList: NavList,
    PageTop: PageTop
  },
  data: function data() {
    return {
      showRightList: true,
      isLogin: false
    };
  },
  watch: {},
  computed: {
    isOrderDetail: function isOrderDetail() {
      return this.$route.name === "orderDetail";
    },
    isOrderDetailRebate: function isOrderDetailRebate() {
      return this.$route.name === "orderDetailRebate";
    },
    isApplyGoods: function isApplyGoods() {
      return this.$route.name === "applyGoods";
    },
    isAfterSaleDetail: function isAfterSaleDetail() {
      return this.$route.name === "afterSaleDetail";
    },
    isSalesDetail: function isSalesDetail() {
      return this.$route.name === "salesDetail";
    }
  },
  created: function created() {
    this.isLogin = account.isLogined();
  },
  methods: {
    onSearch: function onSearch() {},
    handleGetSum: function handleGetSum() {
      this.$refs.handleGetSum.getSum();
    }
  }
};